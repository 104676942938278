import React from "react";
import { Container, Grid, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForward'; // Import the arrow icon
import image from '../../assets/VisitorKioskCode.png';
import setupImage1 from '../../assets/Setup(1).png';
import setupImage2 from '../../assets/SignIn(1).png';
import backendImage from '../../assets/IFSBackendCodeSnippet.png';
import CAD from '../../assets/CAD (2).png';
import CADImport from '../../assets/CADImport.png';
import CADImportExample from '../../assets/CADImportExample.png';
import CADExportExample from '../../assets/CADExportExample.png';

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: "50vw",
    marginTop: "3em",
    marginBottom: "auto",
    alignContent: "center",
  },
  section: {
    padding: theme.spacing(4),
  },
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: theme.shape.borderRadius,
    alignContent: "center",
    marginTop: theme.spacing(2),
  },
  heading: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    fontFamily: 'Garamond'
  },
  timeline: {
    marginTop: theme.spacing(4),
  },
  context: {
    marginTop: theme.spacing(4),
  },
  contextHeading: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    fontFamily: 'Garamond'
  },
  contextText: {
    marginBottom: theme.spacing(2),
    fontFamily: 'Garamond-Bold'
  },
  solution: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    fontFamily: 'Garamond'
  },
  fontStyling: {
    fontFamily: 'Garamond'
  },
  solutionContainer: {
    backgroundColor: theme.palette.grey[200], // Light gray background
    padding: theme.spacing(2),
    textAlign: 'center', // Center text
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  arrowContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1), // Minimal padding around the arrow
  },
  taskList: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    '& li': {
      listStyleType: 'none',
      marginBottom: theme.spacing(2),
      '&::before': {
        // content: '"•"',
        fontSize: '1.5rem',
        color: theme.palette.primary.main,
        marginRight: theme.spacing(1),
      },
    },
  },
  taskItem: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  taskNumber: {
    marginRight: theme.spacing(2),
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: 'black',
  },
  taskContent: {
    fontFamily: 'Garamond',
  },
}));

export const Cad = () => {
  const classes = useStyles();

  return (
    <section id="Cad">
      <Container component="main" className={classes.main} maxWidth="md">
        <Box className={classes.section}>
          <Typography variant="h4" className={classes.heading}>
            CAD Import/Export System
          </Typography>
          <Typography variant="h7">
            How I aided clients in modifying their websites in a more efficient manner by simplifying complex flows and eliminating unnecessary features.
          </Typography>
          <img src={CAD} alt="CMS UI Design" className={classes.image} />
        </Box>
        
        <Grid container spacing={4} className={classes.timeline}>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" color="textSecondary">Timeline</Typography>
            <Typography variant="body1">May 2024 (~3 Months)</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" color="textSecondary">Roles</Typography>
            <Typography variant="body1">Lead Backend Developer, Researcher</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" color="textSecondary">Tools Used</Typography>
            <Typography variant="body1">AutoCAD, Visual Studio, Microsoft SQL</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" color="textSecondary">Responsibilities</Typography>
            <Typography variant="body1">User Research, Library Development, Business considerations</Typography>
          </Grid>
        </Grid>

        <Box className={classes.context}>
          <Typography variant="h5" className={classes.contextHeading}>
            CONTEXT - Streamlining check-in work flow
          </Typography>
          <Typography variant="body1" className={classes.contextText}>
            The Workspace Reservation module within the Indoor Finders system previously allowed employees to book workspaces efficiently. I enhanced this capability by designing and developing a robust platform for the CAFM system, enabling seamless import and export of .DWG and .DXF files. This system processes entity information by identifying polylines and extracting associated text data within the CAD files. I also implemented conversion processes to transform DWG files into PNG format and enabled the transfer of detected polyline vertices to a backend database server. Additionally, I developed functionalities to dynamically update DWG files with modified data, including text and shapes, ensuring continuous and efficient data interchange.
          </Typography>
          <Typography variant="body1" className={classes.contextText}>
            In response to the company's strategic initiative to transition from a commercial library to an in-house CAD processing system, following the success of my recent iOS project, I was awarded the leading role for the development of core functionalities for DWG file handling. This project facilitated the company's entry into a new market segment.
          </Typography>
        </Box>

        <Box className={classes.solutionContainer}>
          <Typography variant="h5" className={classes.solution}>
            IMPORT
          </Typography>
        </Box>
        <Box>
          <img src={CADImport} className={classes.image} />
          <Typography variant="body1" className={classes.contextText}>
            Each import consists of a CAD Dwg file which will be processed by the library and with the information stored in a database
          </Typography>
        </Box>

        <Box className={classes.solutionContainer}>
          <Typography variant="h5" className={classes.solution}>
            EXPORT
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={12} sm={5}>
              <img src={CADImportExample} alt="CAD Import Example" className={classes.image} />
            </Grid>
            <Grid item xs={12} sm={2} className={classes.arrowContainer}>
              <ArrowForwardIosIcon fontSize="large" />
            </Grid>
            <Grid item xs={12} sm={5}>
              <img src={CADExportExample} alt="CAD Export Example" className={classes.image} />
            </Grid>
          </Grid>
        </Box>
        <Typography variant="h5" className={classes.contextHeading}>
          WHAT THIS DOES
        </Typography>

        <ul className={classes.taskList}>
          <li className={classes.taskItem}>
            <span className={classes.taskNumber}>1</span>
            <Typography variant="body1" className={classes.taskContent}>
              Processes DWG and DXF files by identifying polylines and extracting associated text data, making it easier to manipulate and analyze CAD data.
            </Typography>
          </li>
          <li className={classes.taskItem}>
            <span className={classes.taskNumber}>2</span>
            <Typography variant="body1" className={classes.taskContent}>
              Converts DWG files to PNG format, enabling easy visualization of CAD drawings without the need for specialized software.
            </Typography>
          </li>
          <li className={classes.taskItem}>
            <span className={classes.taskNumber}>3</span>
            <Typography variant="body1" className={classes.taskContent}>
              Stores and transfers detected polyline vertices to a backend database server, allowing for structured storage and future manipulation of CAD data.
            </Typography>
          </li>
          <li className={classes.taskItem}>
            <span className={classes.taskNumber}>4</span>
            <Typography variant="body1" className={classes.taskContent}>
              Enables dynamic updates of CAD files with modified data, including text and shapes, ensuring continuous and efficient data interchange.
            </Typography>
          </li>
        </ul>
      </Container>
    </section>
  );
};