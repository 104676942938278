/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { Typography } from "@material-ui/core";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";


import "./SideNavbar.css";


const useStyles = makeStyles((theme) => ({
  font: {
    fontFamily: 'Garamond',
    fontSize: 25
    // textAlign: 'center',
  },
}));

export const SideNavbar = () => {
  const [activeNav, setActiveNav] = useState('#');

  const classes = useStyles();

  return (
    <nav>
      <a href="#" 
        onClick={() => setActiveNav('#')}
        className={activeNav === '#' ? 'active' : ''}
      >
        <Typography className={classes.font}>
          Home
        </Typography>
      </a>
      <a href="#works"
        onClick={() => setActiveNav('#works')}
        className={activeNav === '#works' ? 'active' : ''}
      >
        <Typography className={classes.font}>
          Works
        </Typography>
      </a>
      {/* <a href="#about" 
        onClick={() => setActiveNav('#about')}
        className={activeNav === '#about' ? 'active' : ''}
      >
        <Typography className={classes.font}>
          About
        </Typography>
      </a> */}
      {/* <a href="#contact"
        onClick={() => setActiveNav('#contact')}
        className={activeNav === '#contact' ? 'active' : ''}
      >
        <Typography className={classes.font}>
          Contact
        </Typography>
      </a> */}
    </nav>
  );
};