import React from "react";
import { Container, Grid, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import image from '../../assets/VisitorKioskCode.png';
import setupImage1 from '../../assets/Setup(1).png';
import setupImage2 from '../../assets/SignIn(1).png';
import backendImage from '../../assets/IFSBackendCodeSnippet.png';
import backendImage2 from '../../assets/IFSBackendUI.png';

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: "50vw",
    marginTop: "3em",
    marginBottom: "auto",
    alignContent: "center",
  },
  section: {
    padding: theme.spacing(4),
  },
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
  },
  heading: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    fontFamily: 'Garamond',
  },
  timeline: {
    marginTop: theme.spacing(4),
  },
  context: {
    marginTop: theme.spacing(4),
  },
  contextHeading: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    fontFamily: 'Garamond',
  },
  contextText: {
    marginBottom: theme.spacing(2),
    fontFamily: 'Garamond',
  },
  solution: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    fontFamily: 'Garamond',
  },
  fontStyling: {
    fontFamily: 'Garamond',
  },
  solutionContainer: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2),
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  taskList: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    '& li': {
      listStyleType: 'none',
      marginBottom: theme.spacing(2),
      '&::before': {
        // content: '"•"',
        fontSize: '1.5rem',
        color: theme.palette.primary.main,
        marginRight: theme.spacing(1),
      },
    },
  },
  taskItem: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  taskNumber: {
    marginRight: theme.spacing(2),
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: 'black',
  },
  taskContent: {
    fontFamily: 'Garamond',
  },
}));

export const Kiosk = () => {
  const classes = useStyles();

  return (
    <section id="kiosk">
      <Container component="main" className={classes.main} maxWidth="md">
        <Box className={classes.section}>
          <Typography variant="h4" className={classes.heading}>
            Visitor Kiosk Service Module
          </Typography>
          <Typography variant="h7" className={classes.fontStyling}>
            How I aided clients in modifying their websites in a more efficient manner by simplifying complex flows and eliminating unnecessary features.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <img src={setupImage1} alt="CMS UI Design" className={classes.image} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <img src={setupImage2} alt="CMS UI Design" className={classes.image} />
            </Grid>
          </Grid>
        </Box>
        
        <Grid container spacing={4} className={classes.timeline}>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" color="textSecondary">Timeline</Typography>
            <Typography variant="body1">August 2022 (~2 Years)</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" color="textSecondary">Roles</Typography>
            <Typography variant="body1">Frontend Developer, Backend Developer, Product Designer</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" color="textSecondary">Tools Used</Typography>
            <Typography variant="body1">Xcode, UIKit, Visual Studio, Microsoft SQL</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" color="textSecondary">Responsibilities</Typography>
            <Typography variant="body1">User Research, Design Direction, Business considerations</Typography>
          </Grid>
        </Grid>

        <Box className={classes.context}>
          <Typography variant="h5" className={classes.contextHeading}>
            CONTEXT - Streamlining check-in work flow
          </Typography>
          <Typography variant="body1" className={classes.contextText}>
            The Visitor Kiosk service module is a key component of Indoor Finder's workspace optimization suite, designed to enhance the efficiency of visitor management. Its primary goal is to streamline the entire process, from visitor check-in to notifying the host.
          </Typography>
          <Typography variant="body1" className={classes.contextText}>
            As the primary software developer, I took a pre-existing HTML5 service application and redesigned it to create a more robust and enjoyable user experience by developing a native iOS version using Swift UIKit, engineering both frontend and backend components, optimizing business logic and sign-in workflows, integrating third-party SDKs, and enhancing system functionality and performance through expanded C# libraries and an administrative HTML UI.
          </Typography>
        </Box>

        <Box className={classes.solutionContainer}>
          <Typography variant="h5" className={classes.solution}>
            FRONTEND
          </Typography>
        </Box>
        <Box>
          <img src={image} className={classes.image} />
          <Typography variant="h5" className={classes.contextHeading}>
            MAJOR TASKS 
          </Typography>

          <ul className={classes.taskList}>
            <li className={classes.taskItem}>
              <span className={classes.taskNumber}>1</span>
              <Typography variant="body1" className={classes.taskContent}>
                Redesigned and redeveloped ENTIRETY of preexisting service application as an iOS Native application (including ALL Viewcontrollers and ALL code files)
              </Typography>
            </li>
            <li className={classes.taskItem}>
              <span className={classes.taskNumber}>2</span>
              <Typography variant="body1" className={classes.taskContent}>
                Tested and iterated on UI Design, minimizing # of clicks visitor would need to take per visit to ensure a smooth experience.
              </Typography>
            </li>
            <li className={classes.taskItem}>
              <span className={classes.taskNumber}>3</span>
              <Typography variant="body1" className={classes.taskContent}>
                Deserialized and serialized JSON data from the front and backend ensuring dynamic interchange
              </Typography>
            </li>
            <li className={classes.taskItem}>
              <span className={classes.taskNumber}>4</span>
              <Typography variant="body1" className={classes.taskContent}>
                Recursively updated all Viewcontrollers text depending on set language settings
              </Typography>
            </li>

          </ul>
        </Box>

        <Box className={classes.solutionContainer}>
          <Typography variant="h5" className={classes.solution}>
            BACKEND
          </Typography>
        </Box>
        <Box>
          <img src={backendImage} className={classes.image} />
          <img src={backendImage2} className={classes.image} />
        </Box>

        <Typography variant="h5" className={classes.contextHeading}>
            MAJOR TASKS 
          </Typography>

        <ul className={classes.taskList}>
            <li className={classes.taskItem}>
              <span className={classes.taskNumber}>1</span>
              <Typography variant="body1" className={classes.taskContent}>
                Implemented new HTML textfields for administrative control (IP Address field, Coredata Fields, NDA Agreement Content, Languagepacks) 
              </Typography>
            </li>
            <li className={classes.taskItem}>
              <span className={classes.taskNumber}>2</span>
              <Typography variant="body1" className={classes.taskContent}>
                Handled incoming POST requests from frontend and processed them in Microsoft SQL
              </Typography>
            </li>
            <li className={classes.taskItem}>
              <span className={classes.taskNumber}>3</span>
              <Typography variant="body1" className={classes.taskContent}>
                Managed/Developed new API end-points
              </Typography>
            </li>
          </ul>

      </Container>
    </section>
  );
};
