import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: 'auto', // Ensure footer sticks to the bottom
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    textAlign: 'center',
    position: 'relative',
    bottom: 0,
    width: '100%',
  },
  footerText: {
    fontFamily: 'Garamond',
  },
}));

export const Footer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.footer}>
      <Typography variant="body1" className={classes.footerText}>
        © 2024 Kyle Chang 
      </Typography>
    </Box>
  );
};
