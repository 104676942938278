import React from "react";
import { useState } from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';
import './Works.css';

// Updated path for images if needed
import Portfolio from '../../assets/recentprojects/react-portfolio.png';
import Veritru from '../../assets/recentprojects/veritru.png';
import Lofo from '../../assets/recentprojects/lofo.png';
import Startup from '../../assets/recentprojects/startup.png';
import Lacalle from '../../assets/recentprojects/lacalle.png';
import VisitorKiosk from '../../assets/Setup(1).png';
import CAD from '../../assets/CAD.png';
import { TextDecrypt } from "../content/TextDecrypt";


const useStyles = makeStyles((theme) => ({
  main: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(10),
    fontSize: '35px',
    // fontFamily: 'Garamond-Bold'
    fontWeight: 'bold'
  },
  gridContainer: {
    justifyContent: 'center',
  },
  gridItem: {
    padding: theme.spacing(2),
    width: '100%',
    height: '450px', // Set a fixed height for each grid item
    maxWidth: '400px', // Set a fixed width for each grid item
  },
  card: {
    height: '100%', // Ensure the card takes up the full height of the grid item
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  },
  image: {
    width: '150px', // Set a fixed width for the image
    height: '150px', // Set a fixed height for the image
    objectFit: 'contain', // Ensure the image scales without distorting
    marginBottom: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: '18px',
    textAlign: 'center',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));

export const Works = () => {
  const classes = useStyles();
  const [projects, setProjects] = useState([
    {
      id: 1,
      title: 'Visitor Kiosk Module',
      description: 'Service',
      alter: 'Startup Project',
      image: `${VisitorKiosk}`,
      link: "/kiosk",
    },
    {
      id: 2,
      title: 'CAD Import/Export System',
      description: 'Service',
      alter: 'Startup Project',
      image: `${CAD}`,
      link: "/cad",
    },
    // {
    //   id: 3,
    //   title: 'Microsoft Outlook Plug-in',
    //   description: 'Service',
    //   alter: 'Startup Project',
    //   image: `${CAD}`,
    //   link: "/cad",
    // },
    // {
    //   id: 2,
    //   title: 'CAD Import/Export System',
    //   description: 'Service',
    //   alter: 'Startup Project',
    //   image: `${CAD}`,
    //   link: "/cad",
    // },
  ]);

  return (
    <section id="works" className={classes.main}>
      <Container>
        <Typography className={classes.header}>
          <TextDecrypt text="/works"/>
        </Typography>
      </Container>
      <Container>
        <Grid container spacing={4} className={classes.gridContainer}>
          {projects.map((project) => (
            <Grid item key={project.id} className={classes.gridItem}>
              <div className={classes.card}>
                <img src={project.image} alt={project.alter} className={classes.image} />
                <h3 className={classes.title}>
                  <Link to={project.link} className={classes.link}>
                    {project.title}
                  </Link>
                </h3>
                <p>{project.description}</p>
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
};
