// import React from 'react';
// import { LogoLink } from '../components/logo/LogoLink';
// import { Content } from '../components/content/Content';
import { Hidden } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
// import DisplacementSphere from '../components/background/DisplacementSphere';
// import { ThemeToggle } from '../components/theme/ThemeToggle';
// import { Resume } from '../components/resume/Resume';
// import { SocialIcons } from '../components/content/SocialIcons';
// import { SpeedDials } from '../components/speedDial/SpeedDial';
// import { SideNavbar } from '../components/nav/SideNavbar';
// import { Works } from '../components/works/Works';
// import { About } from '../components/about/About';
// import { Contact } from '../components/contact/Contact';

// const useStyles = makeStyles(() => ({
//   root: {
//     display: 'flex',
//     flexDirection: 'column',
//     minHeight: '100vh',
//   },
// }));

// export const Home = () => {
//   const classes = useStyles();

//   return (
//     <>
//       <div className={classes.root} id="home">
//         {/* <DisplacementSphere /> */}
//         {/* <LogoLink /> */}
//         <Content />
//         <ThemeToggle />
//         <Hidden smDown>
//           <SocialIcons />
//         </Hidden>
//         <Hidden mdUp>
//           <SpeedDials />
//         </Hidden>
//         <Resume />
//       </div>
//       <SideNavbar />
//       <Works />
//       <About />
//       <Contact />
//     </>
//   );
// };



import React from 'react';
import { HashRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Content } from '../components/content/Content';
import { ThemeToggle } from '../components/theme/ThemeToggle';
import { Resume } from '../components/resume/Resume';
import { SocialIcons } from '../components/content/SocialIcons';
import { SpeedDials } from '../components/speedDial/SpeedDial';
import { SideNavbar } from '../components/nav/SideNavbar';
import { Works } from '../components/works/Works';
import { About } from '../components/about/About';
import { Contact } from '../components/contact/Contact';
import { Kiosk } from '../components/works/Kiosk';
import { Cad } from '../components/works/Cad';
import { Footer } from '../components/footer/Footer'; // Import the Footer component


const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
}));

const MainContent = () => {
  const location = useLocation();
  const classes = useStyles();

  const renderContent = () => {
    switch (location.pathname) {
      case '/works':
        return <Works />;
      case '/about':
        return <About />;
      case '/contact':
        return <Contact />;
      case '/kiosk':
        return <Kiosk />;
      case '/cad':
        return <Cad />;
      default:
        return <Content />;
    }
  };

  return (
    <div className={classes.root} id="home">
      {renderContent()}
      <ThemeToggle />
      <SpeedDials />
      <Resume />
      {/* Conditionally render the Footer */}
      {location.pathname !== '/' && <Footer />}
    </div>
  );
};

export const Home = () => {
  return (
    <Router>
      <MainContent />
      <SideNavbar />
    </Router>
  );
};

